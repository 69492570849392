<template>
    <div>
        <nav-bar :title="info.order_state>1?'支付成功':'支付失败'" url="/order"></nav-bar>
        <div class="success">
            <div class="success-icon">
                <van-icon :name="info.order_state>1?'passed':'close'" size="30"></van-icon>
            </div>
            <div class="success-text">
                {{info.order_state>1?'支付成功':'支付失败'}}
            </div>
            <div class="msg">
                付款成功后凭检票二维码进入景区！ <br>
                请您进入景区时，注意做好安全防护措施，带小孩的家长，请看护好您的小孩，安全不怠，旅游愉快。
            </div>
        </div>
        <div class="action">
            <van-button type="primary" round @click="url('/package_detail?id=' +  info.id)" :color="config.main_color"
                size="large">返回订单
            </van-button>
        </div>

    </div>
</template>

<script>
    import {
        package_success
    } from '@/api/package.js';
    export default {
        name: 'package_success',
        data() {
            return {
                info: {},
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {

            //获取订单列表
            get_data() {
                package_success({
                    id: this.$route.query.id,
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                    }
                });
            },


            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .success {
        height: 300px;
        width: 100%;
        background: var(--main-color);
        text-align: center;
        color: #fff;
    }


    .success-icon {
        padding-top: 60px;
    }

    .success-text {
        font-size: 16px;
    }

    .msg {
        text-align: center;
        font-size: 18px;
        color: #fff;
        margin-top: 20px;
        font-weight: 900;
        padding: 15px;
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }

    .van-button--primary {
        background-image: url('../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }
</style>