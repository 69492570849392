import request from '@/utils/request'
export function package_data(data) {
    return request({url: 'package_data',data});
}
export function package_submit(data) {
    return request({url: 'package_submit',data});
}
export function package_pay(data) {
    return request({url: 'package_pay',data});
}
export function package_success(data) {
    return request({url: 'package_success',data});
}
export function package_cancel(data) {
    return request({url: 'package_cancel',data});
}
export function package_refund(data) {
    return request({url: 'package_refund',data});
}
export function package_detail(data) {
    return request({url: 'package_detail',data});
}
export function package_extend(data) {
    return request({url: 'package_extend',data});
}